body {
    font-family: Calibre-Medium, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000;
}

.app {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.header {
    flex: 1;
    margin-right: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px 20px;
    background-color: #fff;
    z-index: 10;
    width: 400px;
    height: 100vh;
    overflow-y: auto;
}

h1,
h2,
p,
a {
    font-size: 18px;
    line-height: 19.8px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

a {
    color: #000;
    text-decoration: none;
}

main {
    margin-left: 400px;
    padding: 15px 20px;
}

section {
    margin-right: 50px;
}

.content {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1;
}

.subtitle {
    font-size: 0.8em;
    font-weight: normal;
}

.column:first-child {
    margin-right: 2rem;
}

@media (max-width: 768px) {
    .app {
        flex-direction: column;
        padding: 1rem;
        max-width: 100%;
    }

    .header {
        position: relative;
        width: auto;
        height: auto;
        margin: 0;
        padding: 1rem;
        overflow-y: visible;
    }

    main {
        margin: 0;
        padding: 1rem;
    }
    

    .content {
        flex-direction: column;
    }

    section {
        margin: 0 0 2rem 0;
        width: 100%;
    }

    .column {
        width: 100%;
        margin: 0 0 2rem 0;
    }

    .column:last-child {
        margin-bottom: 0;
    }
}

